import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TransactionsService } from 'src/app/services/transactions.service';
import { VariablesService } from 'src/app/services/variables.service';

@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss'],
})
export class SalesDashboardComponent implements OnInit {
  salesArray = [];
  message = '';
  showsuccess: boolean = false;
  showMessage: boolean = false;
  showSpinner: boolean = false;
  searchForm: FormGroup;
  constructor(
    private transaction: TransactionsService,
    private formBuilder: FormBuilder,
    private router: Router,
    private variableService: VariablesService
  ) {}

  ngOnInit(): void {
    this.resetNotifications();
    this.getTransactions();
    this.initForm();
  }

  getTransactions() {
    this.showSpinner = true;
    this.resetNotifications();
    this.transaction.getTransactions().subscribe((response) => {
      if (response['body']['success']) {
        this.salesArray = response['body']['body'].sort((a, b) => {
          let dateA = new Date(a.transactionDate);
          let dateB = new Date(b.transactionDate);

          return (dateB.getTime()-dateA.getTime());
        });
        this.showSpinner = false;
      } else {
        this.showMessage = true;
        this.message = response['body']['message'];
      }
    });
  }

  initForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required],
    });
  }

  resetNotifications() {
    this.message = '';
    this.showsuccess = false;
    this.showMessage = false;
  }

  statusFilter(status) {
    this.resetNotifications();
    this.salesArray = this.salesArray.filter(
      (transaction) =>
        (transaction.status.S === undefined
          ? transaction.status
          : transaction.status.S) === status
    );
  }

  modifiyTransactionStatus(transaction, deliveryStatus) {
    this.resetNotifications();
    this.transaction
      .changeTransactioNStatus({
        paymentReference:
          transaction.paymentReference.S || transaction.paymentReference,
        status: deliveryStatus,
        document: transaction.document.N || transaction.document,
      })
      .subscribe((response) => {
        if (response['body']['success']) {
          this.showsuccess = true;
          this.message = response['body']['message'];
        } else {
          this.showMessage = true;
          this.message = response['body']['message'];
        }
      });
  }

  confirmTransaction(transaction) {
  }

  onSubmit() {
    this.resetNotifications();
    this.showMessage = false;
    this.message = '';
    this.transaction
      .searchTransactions(this.searchForm.value.search)
      .subscribe((response) => {
        if (response['body']['success']) {
          this.salesArray = response['body']['body']; //.sort((a,b)=>a.);
        } else {
          this.showMessage = true;
          this.message = response['body']['message'];
        }
      });
  }

  redirect(reference) {
    this.router.navigate(['dashboard/ventas', reference, 'detail']);
  }
}
