// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //url:"https://5iu7p7e6sh.execute-api.us-east-1.amazonaws.com/desarrollo/",
  url:"https://5iu7p7e6sh.execute-api.us-east-1.amazonaws.com/produccion/",

  amplify:{
    region: "us-west-1",
    userPoolId: "us-east-1_UCclQQpuz",
    userPoolWebClientId: "6l673a8h50vt5d8ligoa8i84q3",
    mandatorySignIn: false
  },
  apikey:'iDHNCThUPv5QQlOoNY7Ri4f21ZI72J1S5dRvxUNU',
  urlS3:"https://s3.amazonaws.com/prod.decorazzia/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
