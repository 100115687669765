import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../services/product.service';
import { VariablesService } from '../../services/variables.service';
import { Response } from '../../model/model.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss'],
})
export class ListProductsComponent implements OnInit {
  public productList = [];
  public reponse = {} as Response;
  public message = '';
  public showAlert: boolean = false;
  public searchForm: FormGroup;
  public url = environment.urlS3;
  showSpinner: boolean = true;

  constructor(
    private productService: ProductService,
    private variableService: VariablesService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.variableService.updateShowVariable(false);
  }

  ngOnInit(): void {
    this.initForm();
    this.getAllProduct();

  }

  public initForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required],
    });
  }
  public onSubmit() {
    this.showAlert = false;
    this.message = '';
    this.productService
      .searchProducts(this.searchForm.value.search)
      .subscribe((response) => {
        if (response['body']['success']) {
          this.productList = response['body']['body'];
        } else {
          this.showAlert = true;
          this.message = response['body']['message'];
        }
      });
  }

  public getAllProduct() {
    this.productService.getProducts().subscribe((response) => {
      if (response['body']['success']) {
        this.showSpinner=false;
        this.productList = response['body']['body'].filter(prod=>prod.category && prod.category!=="");
      } else {
        this.showAlert = true;
        this.message = response['body']['message'];
        this.showSpinner=false;
      }
    });
  }

  public prepareProducts(res) {
    let images = [];
    res.forEach((product) => {
      if (product.image) {
        product.image.forEach((image) => {
          images.push({ name: image });
        });
        product.image = images;
        images = [];
      }
    });
    this.productList = res;
  }

  public filterCategory(category) {
    let productsFilter: any = [];
    this.productService.getProducts().subscribe((res) => {
      productsFilter = res['body'];
      this.prepareProducts(
        productsFilter.filter((product) => product.hk === category)
      );
    });
  }

  public filter(isActive) {
    let productsFilter: any = [];
    if (isActive) {
      this.productService.getProducts().subscribe((res) => {
        productsFilter = res['body']['body'];
        this.prepareProducts(
          productsFilter.filter((product) => product.status === 'Activo')
        );
      });
    } else {
      this.productService.getProducts().subscribe((res) => {
        productsFilter = res['body']['body'];
        this.prepareProducts(
          productsFilter.filter((product) => product.status === 'Inactivo')
        );
      });
    }
  }
  public editProduct(product) {
    this.router.navigate(['dashboard/productos', product, 'edit']);
  }
}
