import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TransactionsService } from 'src/app/services/transactions.service';

@Component({
  selector: 'app-sales-detail',
  templateUrl: './sales-detail.component.html',
  styleUrls: ['./sales-detail.component.scss']
})
export class SalesDetailComponent implements OnInit {
  reference: any = "";
  sale: any;
  message = '';
  showsuccess: boolean = false;
  showMessage: boolean = false;
  constructor(
    private transaction: TransactionsService,
    private route: ActivatedRoute

  ) {
    this.reference = this.route.snapshot.paramMap.get('referencia');
    this.transaction
      .searchTransactions(this.reference)
      .subscribe((response) => {
        if (response['body']['success']) {
          this.sale = response['body']['body'][0]
        }
      });
  }

  ngOnInit(): void {
  }

  confirmTransaction(transaction) {
    this.transaction.confirmTransactionStatus(this.sale).subscribe(console.log)
  }

  public resetNotifications() {
    this.message = '';
    this.showsuccess = false;
    this.showMessage = false;
  }

  modifiyTransactionStatus(transaction, deliveryStatus) {
    this.resetNotifications();
    this.transaction
      .changeTransactioNStatus({
        paymentReference:
          transaction.paymentReference.S || transaction.paymentReference,
        status: deliveryStatus,
        document: transaction.document.N || transaction.document,
      })
      .subscribe((response) => {
        if (response['body']['success']) {
          this.showsuccess = true;
          this.message = response['body']['message'];
        } else {
          this.showMessage = true;
          this.message = response['body']['message'];
        }
      });
  }
}
