import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiciosService } from 'src/app/services/servicios.service';

@Component({
  selector: 'app-edit-service-module',
  templateUrl: './edit-service-module.component.html',
  styleUrls: ['./edit-service-module.component.scss'],
})
export class EditServiceModuleComponent implements OnInit {
  showSpinner: boolean = true;
  serviceGot: any = {};
  moduleGot: any = {};
  fileToUpload: File = null;
  showSuccess: boolean = false;
  moduleList: any[] = [];

  moduleGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private service: ServiciosService,
    private route: ActivatedRoute
  ) {
    this.moduleGroup = formBuilder.group({
      active: [''],
      description: [''],
      title: [''],
      url: [''],
    });
    this.chargeModule()
  }

  chargeModule() {
    this.service.getServices().subscribe((res) => {
      this.showSpinner = false;
      const service = this.route.snapshot.paramMap.get('servicio');
      const serviceModule = this.route.snapshot.paramMap.get('module');
      this.serviceGot = res['body']['body']['services'].find(
        (item) => item['title'] === service
      );
      if (this.serviceGot)
        this.moduleList = this.serviceGot['modules'];
      this.moduleGot = this.serviceGot['modules'].find(
        (item) => item['title'] === serviceModule
      );
      this.initForm(this.moduleGot);
    });
  }

  initForm(module) {
    this.moduleGroup = this.formBuilder.group({
      active: [module.active],
      description: [module.description],
      title: [module.title],
      url: [module.url],
    });
  }

  onFileChanges(file) {
    this.moduleGot.url = file[0];
    this.save();
  }

  save() {
    this.showSpinner = true;
    let moduleAux = this.moduleList.filter((item)=>item.title !== this.moduleGot.title)
    moduleAux.push({
      active: this.moduleGroup.controls['active'].value,
      description: this.moduleGroup.controls['description'].value,
      title: this.moduleGroup.controls['title'].value,
      url: this.moduleGot.url,
    })
    const serviceToSave = {
      isNew: false,
      active: this.serviceGot.active,
      description: this.serviceGot.description,
      modules: moduleAux,
      title: this.serviceGot.title,
      url: this.serviceGot.url,
    };
    this.service.postService(serviceToSave).subscribe((res) => {
      this.showSuccess = res['body']['success'];
      this.chargeModule();
      this.showSpinner = false;
      this.hideSuccess();
    });
  }

  ngOnInit(): void {}

  hideSuccess() {
    setTimeout(() => {
      this.showSuccess = false;
    }, 3000);
  }
}
