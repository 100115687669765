<div class="container w-100">
    <div class="alert alert-warning" role="alert" *ngIf="showMessage">
        {{message}}
    </div>
    <div class="alert alert-success" role="alert" *ngIf="showsuccess">
        {{message}}
    </div>
    <div>
        <div class="card">
            <div class="card-body">
                <h5 class="card-title alert-warning text-center"
                    *ngIf="(sale.delivaryStatus != undefined && sale.delivaryStatus=='PENDING')">
                    Pendiente por despacho</h5>
                <h5 class="card-title alert-success text-center"
                    *ngIf=" (sale.delivaryStatus != undefined && sale.delivaryStatus=='DONE')">
                    Despacho listo</h5>
                <h5 class="card-title alert-danger text-center"
                    *ngIf="(sale.delivaryStatus != undefined && sale.delivaryStatus=='DISCARD')">
                    Descartado</h5>
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <small class="card-text text-left font-weight-bold">Referencia</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{sale.paymentReference }}</small>
                        </div>
                    </div>
                    <div class="row alert-dark" *ngIf=" (sale.status != undefined && sale.status =='APPROVED') ">
                        <div class="col">
                            <small class="card-text text-left font-weight-bold">Pago</small>
                        </div>
                        <div class="col">
                            <small class="card-text alert-success text-center">{{ sale.status}}</small>
                        </div>
                    </div>
                    <div class="row alert-dark" *ngIf=" (sale.status != undefined && sale.status =='PENDING')">
                        <div class="col">
                            <small class="card-text text-left font-weight-bold">Pago</small>
                        </div>
                        <div class="col">
                            <small class="card-text alert-warning text-center">{{ sale.status}}</small>
                        </div>
                    </div>
                    <div class="row alert-dark" *ngIf=" (sale.status != undefined && sale.status =='DECLINED')">
                        <div class="col">
                            <small class="card-text text-left  font-weight-bold">Pago</small>
                        </div>
                        <div class="col">
                            <small class="card-text alert-danger text-center">{{ sale.status}}</small>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col">
                            <small class="card-text text-left font-weight-bold">Fecha de pago</small>
                        </div>
                        <div class="col">
                            <small class="card-text text-center">{{sale.transactionDate}}</small>
                        </div>
                    </div>
                    <div class="row alert-dark" *ngIf="sale.enterprise!=undefined && sale.enterprise =='' ">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Cliente</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{sale.name}} {{ sale.lastName}}
                            </small>
                        </div>
                    </div>
                    <div class="row alert-dark"
                        *ngIf="(sale.enterprise!=undefined && sale.enterprise !='' && sale.name=='')">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Empresa</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{sale.enterprise}} </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Tipo de documento</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{sale.docType}} </small>
                        </div>
                    </div>
                    <div class="row alert-dark">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Documento</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{ sale.document}} </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Télefono</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{ sale.cellphone}} </small>
                        </div>
                    </div>
                    <div class="row alert-dark">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Email</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{ sale.email}} </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Dirección</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{ sale.address}} </small>
                        </div>
                    </div>
                    <div class="row alert-dark" *ngIf="sale.additionalAddress!=''">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Dirección adicional</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{ sale.additionalAddress || ' '}}
                            </small>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Departamento</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{sale.city}} </small>
                        </div>
                    </div>
                    <div class="row alert-dark">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Municipio</small>
                        </div>
                        <div class="col">
                            <small class="card-text">{{ sale.state}} </small>
                        </div>
                    </div>
                    <br>
                    <small class="card-text text-left font-weight-bold">Artículos vendidos</small>
                    <table class="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Referencia</th>
                                <th scope="col">Categoría</th>
                                <th scope="col">Cantidad</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="sale.items!= undefined">
                            <tr *ngFor="let item of sale.items">
                                <td>{{item.sk}}</td>
                                <td>{{item.category}}</td>
                                <td>{{item.count}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <br>
                   <!--  <div class="row alert-dark">
                        <div class="col text-left">
                            <small class="card-text text-left font-weight-bold">Validar venta</small>
                        </div>
                    </div> -->
                    <div class="row alert-dark padding">
                        <!-- <div class="col">
                            <button class="btn-view" type="button" (click)="confirmTransaction(sale)">
                                Imprimir
                            </button>
                        </div> -->
                        <div class="col">
                            <div class="dropdown">
                                <button class="btn-view dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Cambiar estado
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" (click)="modifiyTransactionStatus(sale,'DONE')"
                                        *ngIf="(sale.status != undefined && sale.status !='DECLINED' )">Despachado</a>
                                    <a class="dropdown-item" (click)="modifiyTransactionStatus(sale, 'PENDING')"
                                        *ngIf="(sale.status != undefined && sale.status !='DECLINED')">Pendiente</a>
                                    <a class="dropdown-item" (click)="modifiyTransactionStatus(sale, 'DISCARD')"
                                        *ngIf="(sale.status != undefined && sale.status =='DECLINED')">Descartado</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>