<div class="alert alert-warning" role="alert" *ngIf="showAlert">
  {{message}}
</div>
<div class="row">
  <div class="col-6">
    <form class="form-inline" [formGroup]="searchForm"  (ngSubmit)="onSubmit()" >
      <input class="input-size form-control mr-sm-2" formControlName="search" type="search" aria-label="Search">
      <button class="btn-view my-2 my-sm-0" type="submit" [disabled]="searchForm.status =='INVALID'">Buscar</button>
      <button class="btn-view" type="button" (click)="getAllProduct()" >
        <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-arrow-clockwise" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M3.17 6.706a5 5 0 0 1 7.103-3.16.5.5 0 1 0 .454-.892A6 6 0 1 0 13.455 5.5a.5.5 0 0 0-.91.417 5 5 0 1 1-9.375.789z"/>
          <path fill-rule="evenodd" d="M8.147.146a.5.5 0 0 1 .707 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 1 1-.707-.708L10.293 3 8.147.854a.5.5 0 0 1 0-.708z"/>
        </svg>
      </button>
    </form>
  </div>
  <div class="col-1">
    <div class="btn-group">
      <button type="button" class="btn-view dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Estados
      </button>
      <div class="dropdown-menu">
        <a class="dropdown-item" (click)="filter(true)" >Activos</a>
        <a class="dropdown-item"  (click)="filter(false)">Inactivo</a>
      </div>
    </div>
  </div>    
</div>
<div class="container">
  <div class="card-deck">
      <div class="card mb" *ngFor="let catalog of productList">
        <img class="card-img-top" *ngIf="catalog.image" src="{{catalog?.image[0]}}" alt="Card image cap">
        <div class="footer-card">
          <ul class="list-unstyled list-inline font-small mt-3">
              <u class="list-inline-item pr-2">{{catalog.sk}}</u>
              <li class="list-inline-item pr-2">
                <P class="white-text">{{catalog.status}}                    
                </P> 
            </li>
              <li class="list-inline-item pr-2">
                  <P class="white-text">{{catalog.view}}
                      <svg width="1em" name="views" height="1em" viewBox="0 0 16 16" class="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"/>
                          <path fill-rule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                      </svg>
                  </P> 
              </li>
              <li class="list-inline-item pr-2">
                
              </li>
              <li class="list-inline-item">
                  <!-- <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-bag" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" d="M8 1a2.5 2.5 0 0 0-2.5 2.5V4h5v-.5A2.5 2.5 0 0 0 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5H2z"/>
                    </svg> -->
              </li>
              <div class="btn-div">
                <button class="btn btn-light" data-toggle="modal" data-target="#exampleModalCenter" (click) ="editProduct(catalog.sk)">EDITAR</button>
              </div>
          </ul>
        </div>          
      </div>
  </div> 
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modalsize" role="document" style="min-width: 80%;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">Modificar producto</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <app-add-products></app-add-products>
        </div>    
      </div>
    </div>
</div>

<app-spinner *ngIf="showSpinner"></app-spinner>
