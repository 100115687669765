import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VariablesService } from 'src/app/services/variables.service';
import { TransactionsService } from '../../services/transactions.service';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss'],
})
export class SalesComponent implements OnInit {
  public salesArray = [];
  public message = '';
  public showsuccess: boolean = false;
  public showMessage: boolean = false;
  public searchForm: FormGroup;

  constructor(
    private transaction: TransactionsService,
    private formBuilder: FormBuilder,
    private variableService: VariablesService
  ) {
    this.variableService.updateShowVariable(false);
  }

  ngOnInit(): void {
    this.resetNotifications();
    this.getTransactions();
    this.initForm();
  }

  public getTransactions() {
    this.resetNotifications();
    this.transaction.getTransactions().subscribe((response) => {
      if (response['body']['success']) {
        this.salesArray = response['body']['body'].sort();
      } else {
        this.showMessage = true;
        this.message = response['body']['message'];
      }
    });
  }

  public initForm() {
    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required],
    });
  }

  public resetNotifications() {
    this.message = '';
    this.showsuccess = false;
    this.showMessage = false;
  }

  public statusFilter(status) {
    this.resetNotifications();
    this.salesArray = this.salesArray.filter(
      (transaction) =>
        (transaction.status.S === undefined
          ? transaction.status
          : transaction.status.S) === status
    );
  }

  public modifiyTransactionStatus(transaction, deliveryStatus) {
    this.resetNotifications();
    this.transaction
      .changeTransactioNStatus({
        paymentReference:
          transaction.paymentReference.S || transaction.paymentReference,
        status: deliveryStatus,
        document: transaction.document.N || transaction.document,
      })
      .subscribe((response) => {
        if (response['body']['success']) {
          this.showsuccess = true;
          this.message = response['body']['message'];
        } else {
          this.showMessage = true;
          this.message = response['body']['message'];
        }
      });
  }

  public confirmTransaction(transaction) {
  }

  public onSubmit() {
    this.resetNotifications();
    this.showMessage = false;
    this.message = '';
    this.transaction
      .searchTransactions(this.searchForm.value.search)
      .subscribe((response) => {
        if (response['body']['success']) {
          this.salesArray = response['body']['body']; //.sort((a,b)=>a.);
        } else {
          this.showMessage = true;
          this.message = response['body']['message'];
        }
      });
  }
}
